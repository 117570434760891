import React, { useState, useEffect } from "react";
// import { Form } from "react-bootstrap";
import { Select, Form, Input, Switch } from "antd";
import axios from "axios";
import Papa from "papaparse"; // For parsing CSV data

import "./index.css";
const { Option } = Select;

const errStyle = {
  color: "red",
  fontSize: 14,
  padding: 0,
  margin: 5,
  // fontWeight: "bold",
  // fontStyle: "italic",
};

export const ContactInfo = () => {
  return (
    <>
      <p style={{ fontSize: 20 }}>
        We Hope Everything is Going Fine,
        <br /> If Anything Goes Wrong Please Contact Us
        <br /> support@testaing.com
      </p>
    </>
  );
};

export const ModelSignUp = (props) => {
  const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const {
    fullName,
    setFullName,
    email,
    setEmail,
    setPassword,
    password,
    nameError,
    setNameError,
    emailError,
    setEmailError,
    passwordError,
    setPasswordError,
  } = props;

  useEffect(() => {
    if (fullName.length < 3) {
      setNameError("Name must be 3 characters long!");
    } else {
      setNameError("");
    }
  }, [fullName]);

  useEffect(() => {
    if (!EMAIL_REGEX.test(email)) {
      setEmailError("Please enter valid email!");
    } else {
      setEmailError("");
    }
  }, [email]);

  useEffect(() => {
    if (password.length < 6) {
      setPasswordError("Password must be 6 characters long!");
    } else {
      setPasswordError("");
    }
  }, [password]);

  return (
    <>
      <div>
        <h3>Signup To Proceed</h3>
        <hr />
        <Form autoComplete="off" layout="vertical">
          <Form.Item
            className="username"
            label="Full Name"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please put your full name!",
              },
            ]}
          >
            <Input
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
              placeholder="Eg:- User"
              onBlur={() => setNameTouched(true)}
            />
            {nameTouched && nameError != "" && (
              <p style={errStyle}>{nameError}</p>
            )}
          </Form.Item>
          <Form.Item
            className="username"
            label="Email"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please put your email!",
              },
            ]}
          >
            <Input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Eg:- user@testaing.com"
              onBlur={() => setEmailTouched(true)}
            />
            {emailTouched && emailError != "" && (
              <p style={errStyle}>{emailError}</p>
            )}
          </Form.Item>
          <Form.Item
            className="username"
            label="Password"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please your full name!",
              },
            ]}
          >
            <Input.Password
              value={password}
              placeholder="input password"
              onChange={(event) => setPassword(event.target.value)}
              autoComplete="off"
              onBlur={() => setPasswordTouched(true)}
            />
            {passwordTouched && passwordError != "" && (
              <p style={errStyle}>{passwordError}</p>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export const ModelTopic = (props) => {
  const [topicTouched, setTopicTouched] = useState(false);
  const { topic, setTopic, inputError, setInputError } = props;
  useEffect(() => {
    if (topic.length < 3) {
      setInputError("Project Name must be atleast 3 characters long");
    } else {
      setInputError("");
    }
  }, [topic]);

  return (
    <>
      <div>
        <h3>Enter Project Name</h3>

        <br />
        <Form layout="vertical">
          <Form.Item
            className="username"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please put full project",
              },
            ]}
          >
            <Input
              value={topic}
              onChange={(event) => setTopic(event.target.value)}
              placeholder="Demo Project"
              onBlur={() => setTopicTouched(true)}
            />
            {topicTouched && inputError != "" && (
              <p style={errStyle}>{inputError}</p>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export const ModelInput = (props) => {
  const [inputTouched, setInputTouched] = useState(false);
  const { colInput, setColInput } = props;

  const [columnNames, setColumnNames] = useState([]);
  let dataset = localStorage.getItem("dataset") || null;
  // Replace with your S3 file URL

  useEffect(() => {
    // Fetch the CSV file from S3
    const fetchCsv = async () => {
      if (!dataset) {
        console.log("dataset not found");

        return;
      }
      try {
        const response = await axios.get(dataset, { responseType: "blob" });

        // Parse the CSV to extract column names
        Papa.parse(response.data, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            if (results.meta && results.meta.fields) {
              console.log(results.meta.fields);

              setColumnNames(results.meta.fields); // Set column names from the header row
            }
          },
          error: (error) => {
            console.error("Error parsing CSV:", error);
          },
        });
      } catch (error) {
        console.error("Error fetching CSV file:", error);
      }
    };

    fetchCsv();
  }, []);

  useEffect(() => {
    setColumnNames(
      columnNames.filter((columnName) => {
        return (
          colInput.target !== columnName &&
          !colInput.sensitiveAttributes.includes(columnName)
        );
      })
    );
  }, [colInput.target, colInput.sensitiveAttributes]);

  return (
    <>
      <div className="d-flex flex-column">
        <h3>Enter Column Name</h3>

        <div className="mb-3">
          <h6>Target Column</h6>
          <Select
            style={{ width: 300 }}
            className=""
            placeholder="Select the Column"
            onChange={(e) => {
              setColInput((prev) => {
                const newInput = { ...prev };
                newInput.target = e;
                return newInput;
              });
            }}
          >
            {columnNames.map((column, i) => (
              <Option value={column}>{column}</Option>
            ))}
          </Select>
        </div>

        {/* Another Dropdown */}
        <div className="mb-2">
          <h6>Sensitive Attributes Column</h6>

          <Select
            style={{ width: 300 }}
            placeholder="Select a Column"
            onChange={(e) => {
              setColInput((prev) => {
                const newInput = { ...prev };
                newInput.sensitiveAttributes = e;
                return newInput;
              });
            }}
          >
            {columnNames.map((column, i) => (
              <Option value={column}>{column}</Option>
            ))}
          </Select>
        </div>
        <div>
          <h6>Bias Mitigation</h6>
          <Switch
            className="custom-switch"
            onChange={(e) =>
              setColInput((prev) => {
                const newInput = { ...prev };
                newInput.bias = e;
                return newInput;
              })
            }
          />
        </div>
        {/* Input Field with Validation */}
      </div>
    </>
  );
};
