import Parse from './parse.js'
import axios from 'axios'
export const API_URL =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:3030/'
        : 'https://saas-api.aiensured.com/'
console.log(API_URL)
export const newFile = (file) => {
    let parseFile = new Parse.File(file.name, file)
    parseFile
        .save({
            progress: (value) => {}
        })
        .then((res) => {
            var newStore = new Parse.Object('File')
            newStore.set('File', parseFile)
            newStore.save()
        })
}

export const uploadDataset = (file, dbname) => {
    let roundvalue = 0
    let parseFile = new Parse.File(file.name, file)
    return parseFile
        .save({
            progress: (value) => {
                roundvalue = Math.round(value * 100)
                console.log(roundvalue)
            }
        })
        .then((res) => {
            Parse.User.current().set(dbname, res._url)
            Parse.User.current().save()
            var newStore = new Parse.Object('File')
            newStore.set('File', parseFile)
            newStore.save()
            console.log(res)
            return roundvalue
        })
        .catch((error) => {
            console.log(error)
        })
}

export const uploadModel = (file) => {
    let roundvalue = 0
    let parseFile = new Parse.File(file.name, file)
    return parseFile
        .save({
            progress: (value) => {
                roundvalue = Math.round(value * 100)
                // console.log(roundvalue);
            }
        })
        .then((res) => {
            Parse.User.current().set('model', res._url)
            Parse.User.current().save()
            var newStore = new Parse.Object('File')
            newStore.set('File', parseFile)
            newStore.save()
            console.log(res)
            return roundvalue
        })
        .catch((error) => {
            console.log(error)
        })
}

export const uploadEmbedded = (file) => {
    let roundvalue = 0
    let parseFile = new Parse.File(file.name, file)
    return parseFile
        .save({
            progress: (value) => {
                roundvalue = Math.round(value * 100)
                // console.log(roundvalue);
            }
        })
        .then((res) => {
            Parse.User.current().set('embedded', res._url)
            Parse.User.current().save()
            var newStore = new Parse.Object('File')
            newStore.set('File', parseFile)
            newStore.save()
            console.log(res)
            return roundvalue
        })
        .catch((error) => {
            console.log(error)
        })
}

export const newProject = async (name, datasetFile, modelFile, type) => {
    const Project = Parse.Object.extend('Project')
    const project = new Project()
    project.set('userId', Parse.User.current())
    project.set('dataset', datasetFile)
    project.set('model', modelFile)
    project.set('name', name)
    project.set('status', 'started')
    project.set('deleted', false)
    project.set('type', type)
    return await project.save()
}
export const deleteProject = async (currentProject) => {
    const project = Parse.Object.extend('Project')
    const query = new Parse.Query(project)
    query.equalTo('objectId', currentProject.id)
    const results = await query.first()
    results.set('deleted', true)
    results.save()
    return results
}

export const getProjects = () => {
    const Project = Parse.Object.extend('Project')
    const userQuery = new Parse.Query(Project)
    userQuery.equalTo('userId', Parse.User.current())
    const noUserQuery = new Parse.Query(Project)
    noUserQuery.doesNotExist('userId')
    const mainQuery = Parse.Query.or(userQuery, noUserQuery)
    const deletedFalseQuery = new Parse.Query(Project)
    deletedFalseQuery.equalTo('deleted', false)
    const deletedUndefinedQuery = new Parse.Query(Project)
    deletedUndefinedQuery.doesNotExist('deleted')
    const deletedQuery = Parse.Query.or(
        deletedFalseQuery,
        deletedUndefinedQuery
    )
    const primaryQuery = Parse.Query.and(mainQuery, deletedQuery)
    primaryQuery.descending('createdAt')
    return primaryQuery.find()
}

export const getProjectById = async (route) => {
    const project = Parse.Object.extend('Logs')
    const query = new Parse.Query(project)
    // query.equalTo("userId", Parse.User.current());
    query.equalTo('ID', route)
    const results = await query.first()
    return results
}

export const saveProjectName = (name) => {
    const Project = Parse.Object.extend('Project')
    const project = new Project()
    project.set('name', name)
    return project.save()
}

export const sendEmail = () => {
    Parse.Cloud.run('notification_mail').then((data) => {
        console.log(data)
    })
}

export const runImagePipeline = (
    dataset,
    model,
    projectId,
    colInput,
    type,
    app
) => {
    console.log('run image pipeline')
    const user = Parse.User.current()
    const baseData = {
        dataset,
        model,
        projectId,
        userId: user.id,
        pipeline: type,
        app: app
    }

    // Define additional properties conditionally
    const additionalProperties = {}
    if (colInput !== '') {
        additionalProperties.colInput = colInput
    }
    const params = { ...baseData, ...additionalProperties }

    Parse.Cloud.run('run-pipeline', params)
        .then((data) => {
            console.log(data)
        })
        .catch((error) => {
            console.error('Error sending success status:', error)
        })
}

export const getResults = async (_projectId) => {
    const project = Parse.Object.extend('Project')
    const query = new Parse.Query(project)
    query.equalTo('objectId', _projectId)
    var results = await query.find()
    var resultsUrl = results[0].get('result')
    return resultsUrl
}

export const isOnboarded = () => {
    const user = Parse.User.current()
    return user ? user.get('onboarding') : null
}

export const setOnboardingDone = () => {
    const user = Parse.User.current()
    //console.log(user);
    if (user.get('email')) {
        user.set('onboarding', true)
    }
    return user.save()
}

export const terminateInstance = async (instanceId) => {
    console.log('terminated')
    // Fetch model, dataset & name from current Project
    try {
        return await axios
            .post(API_URL + 'terminate', {
                instanceId: instanceId
            })
            .then((response) => {
                //if (response) {
                console.log(response)
                return response
                //}
            })
    } catch (error) {
        console.log(error)
    }
}

export const cancelledByUser = async (projectId) => {
    const params = {
        projectId: projectId,
        status: 'cancelled'
    }
    const res = await Parse.Cloud.run('terminateInstance', params)
    return res
}

export const applyCoupon = async (code) => {
    if (!code) return
    try {
        const coupons = Parse.Object.extend('coupons')
        const query = new Parse.Query(coupons)
        query.equalTo('code', code)
        const coupon = await query.first()
        if (!coupon) {
            return { error: 'Coupon is not valid' }
        }
        const couponCredit = coupon.get('credits')
        console.log(couponCredit)
        if (!coupon.get('used')) {
            const currCredits = Parse.User.current().get('credits')
            Parse.User.current().set('credits', currCredits + couponCredit)
            Parse.User.current().save()
            coupon.set('used', true)
            const res = await coupon.save()
            console.log('applied coupon')
            return { success: res }
        } else {
            return { error: 'coupon already used' }
        }
    } catch (error) {
        return { error: error }
    }
}

export const buyCredits = async (plan) => {
    if (!plan) return
    let planCredits = 0
    if (plan === 'standard') {
        planCredits = 100
    } else {
        planCredits = 20
    }
    try {
        const Payments = Parse.Object.extend('Payments')
        const query = new Payments()
        query.set('userId', Parse.User.current())
        query.set('credits', planCredits)
        query.set('plan', plan)
        const res = await query.save()
        const currCredits = Parse.User.current().get('credits')
        Parse.User.current().set('credits', currCredits + planCredits)
        Parse.User.current().save()
        console.log('Bought Credits')
        return { success: res }
    } catch (error) {
        return { error: error }
    }
}
